import classNames from 'classnames'
import { ChangeEvent, InputHTMLAttributes, VFC } from 'react'

const fullTypes = ['email', 'password', 'search', 'text']

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  border?: boolean
  testId?: string
  type: string
  prefix?: string
  onValueChange?: (value: string | number) => void
}

export const Input: VFC<InputProps> = ({
  border,
  className,
  name,
  testId,
  value,
  type,
  prefix,
  onChange,
  onValueChange,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onValueChange) {
      const value: string = e.target.value

      if (type === 'number') {
        onValueChange(Number(value))
      } else {
        onValueChange(value)
      }
    } else if (onChange) {
      onChange(e)
    }
  }

  return (
    <div className="flex items-center">
      {prefix && (
        <div className="py-2 px-3 rounded-l bg-gray border border-r-0 border-darkGray whitespace-nowrap">
          {prefix}
        </div>
      )}
      <input
        type={type}
        name={name}
        data-testid={testId || name}
        value={value || ''}
        className={classNames(
          'block py-2 px-3 rounded text-black placeholder-darkGray',
          className,
          {
            'rounded-l-none': prefix,
            'border border-darkGray': border,
            'w-full': fullTypes.includes(type) || !type,
          }
        )}
        onChange={handleChange}
        {...props}
      />
    </div>
  )
}

export default Input
